import React, { useEffect, useRef, useState } from "react";
import { FaArrowRightLong } from "react-icons/fa6";
import { Link } from "react-router-dom";
import VideoIntro from "../assets/intro_v6.mp4";
import VideoThumbnail from "../assets/intro_thumbnail_v3.png";
import { RiPlayLargeFill } from "react-icons/ri";
import { miscEv } from "../misc/ev";

function IntroVideo() {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null); // Create a ref to the video element

  useEffect(() => {
    miscEv("lp");
  }, []);

  const handlePlay = () => {
    if (videoRef.current) {
      if (!isPlaying) {
        videoRef.current.play(); // Play the video programmatically
        setIsPlaying(true); // Update state to hide the overlay
        miscEv("iv");
      }
    }
  };

  return (
    <div className="intro-video ">
      <div className="video-container">
        <video
          ref={videoRef} // Attach the ref to the video element
          src={VideoIntro}
          poster={VideoThumbnail}
          controls={isPlaying}
          muted={false}
          onClick={handlePlay} // Play the video when it's clicked
        />
        {!isPlaying && (
          <div className="video-overlay" onClick={handlePlay}>
            <button className="play-button">
              <RiPlayLargeFill className="icon" />
            </button>
            <p className="instruction">Tik om de video te starten</p>
          </div>
        )}
      </div>

      <Link className="cta button-a" to="/stap1">
        Bekijk de mogelijkheden <FaArrowRightLong />
      </Link>
    </div>
  );
}

export default IntroVideo;
