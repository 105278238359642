import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/reset.css";
import "./assets/main.scss";
import reportWebVitals from "./reportWebVitals";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";

import FormIntake from "./components/FormIntake";
import FormPrice from "./components/FormPrice";
import FormExplanation from "./components/FormExplanation";
import Funnel1 from "./components/Funnel1";
import FormReferral from "./components/FormReferral";
import FormDone from "./components/FormDone";
import Privacy from "./components/Privacy";
import FormWelcomeWithPricingAndIntake from "./components/Form2WelcomeWithPricingAndIntake";
import Form2Address from "./components/Form2Address";

import { AnimatePresence } from "framer-motion";
import MainApp from "./MainApp";

const root = ReactDOM.createRoot(document.getElementById("root"));

const router = createBrowserRouter([
  {
    element: <MainApp />,
    errorElement: <Navigate to="/" replace={true} />,
    children: [
      {
        path: "/privacy",
        element: <Privacy></Privacy>,
      },
      {
        path: "/",
        element: <Funnel1></Funnel1>,
        //errorElement: <Navigate to="/" replace={true} />,
      },
      {
        path: "/stap1",
        element: (
          <FormWelcomeWithPricingAndIntake></FormWelcomeWithPricingAndIntake>
        ),
      },
      {
        path: "/stap2",
        element: <Form2Address></Form2Address>,
      },
      {
        path: "/stap3",
        element: <FormReferral></FormReferral>,
      },
      {
        path: "/einde",
        element: <FormDone></FormDone>,
      },
      /*
        {
          path: "/stap1",
          element: <FormIntake></FormIntake>,
        },
        {
          path: "/stap2",
          element: <FormPrice></FormPrice>,
        },
        {
          path: "/stap3",
          element: <FormExplanation></FormExplanation>,
        },
        {
          path: "/stap4",
          element: <FormReferral></FormReferral>,
        },
        {
          path: "/einde",
          element: <FormDone></FormDone>,
        },*/
    ],
  },
]);

root.render(
  //<React.StrictMode>
  <AnimatePresence mode="wait">
    <div className="base-container">
      <RouterProvider router={router} />
    </div>
  </AnimatePresence>
  //</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
