import React, { useEffect, useState } from "react";
import AnimatedComponent from "./AnimatedComponent";
import { Link, useNavigate } from "react-router-dom";
import { MdChevronLeft } from "react-icons/md";
import { FaArrowRightLong } from "react-icons/fa6";

import axios from "axios";
function Form2Address() {
  const [address, setAddress] = useState("");
  const navigate = useNavigate();

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      // Trigger the button click or any other action here
      handleSubmit();
    }
  };

  const handleSubmit = async (e) => {
    if (e) {
      e.preventDefault();
    }

    if (address.length > 0) {
      try {
        // Get the unique code from localStorage
        const unique_code = localStorage.getItem("unique_code");

        // Get the API base URL from the environment variables
        const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

        // Make POST request to create the WaitlistOrder
        await axios.patch(`${apiBaseUrl}/api/registrants/update-address`, {
          unique_code,
          address,
        });

        // Navigate to the next step
        navigate("/stap3");
      } catch (error) {
        console.error("Error updating address:", error);
        alert("Er is iets misgegaan. Probeer het opnieuw.");
      }
    } else {
      alert("Controleer of uw adres is ingevuld. Bedankt!");
    }
  };

  return (
    <AnimatedComponent>
      <div className="form-explanation">
        <h2>Wat nu?</h2>
        <p>
          Allereerst, héél erg bedankt voor je interesse! €50 korting werd
          alvast gelinkt aan je e-mailadres.
          <br></br>
          <br></br> Ik werk hard om zo snel mogelijk 100 klanten in deze buurt
          te verzamelen die even enthousiast zijn als jij.
          <br></br>
          <br></br>
          <span className="color">
            BELANGRIJK: door je adres hieronder in te vullen, bevestig je je
            interesse
          </span>{" "}
          en beschouw ik u als één van deze 100 klanten.
        </p>
        <input
          type="text"
          placeholder="Adres"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <p>
          Via e-mail houd ik je op de hoogte om de ophaling, betaling, eventuele
          vragen en uitleg te regelen.
        </p>

        <button className="button-a" onClick={handleSubmit}>
          Inschrijven op wachtlijst
        </button>
        <p className="a-question">
          <span>Intussen al een vraag?</span>
          <a href="mailto:info@digidoos.be">info@digidoos.be</a>
        </p>
        <Link className="back-button-text" to="/stap1">
          <MdChevronLeft />
          Terug
        </Link>
      </div>
    </AnimatedComponent>
  );
}

export default Form2Address;
