import axios from "axios";

export async function miscEv(details) {
  try {
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    await axios.post(`${apiBaseUrl}/api/e/${details}`);
  } catch (error) {
    console.error("Error during the post ev:", error);
  }
}
