import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import axios from "axios";

function MainApp() {
  const location = useLocation();

  useEffect(() => {
    // Parse the query parameters from the URL
    const searchParams = new URLSearchParams(location.search);

    // Check if 'b' parameter exists
    const bParam = searchParams.get("b");

    // If 'b' exists, execute the function
    if (bParam) {
      try {
        const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
        axios.post(`${apiBaseUrl}/api/eb/${bParam}`);
      } catch (error) {
        console.error("Error during the post 'eb':", error);
      }
    } else {
      console.log("No b-par found.");
    }
  }, []);

  return <Outlet />;
}

export default MainApp;
