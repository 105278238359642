import React, { useEffect, useRef, useState } from "react";
import { FaArrowLeftLong } from "react-icons/fa6";
import { Link } from "react-router-dom";

function Privacy() {
  return (
    <div className="privacy">
      <Link className="button-a back-button" to="/stap1">
        <FaArrowLeftLong /> Terug
      </Link>
      <h1>Privacy</h1>
      <p>
        'Digidoos.be - Arnaud Verpoucke', hierna genoemd: 'Digidoos', gevestigd
        aan Monica Vancloosterstraat 72, 8800 Roeselare, België, is
        verantwoordelijk voor de verwerking van persoonsgegevens zoals
        weergegeven in deze privacyverklaring.
      </p>

      <h2>Contactgegevens:</h2>
      <p>https://www.digidoos.be</p>
      <p>
        Monica Vancloosterstraat 72<br></br>8800 Roeselare, België
      </p>
      <p>+32 479 46 27 82</p>
      <p>
        Arnaud Verpoucke is de Functionaris Gegevensbescherming van Digidoos.
        Hij/zij is te bereiken via info@digidoos.be
      </p>

      <h2>Persoonsgegevens die wij verwerken</h2>
      <p>
        Digidoos verwerkt uw persoonsgegevens doordat u gebruik maakt van onze
        diensten en/of omdat u deze zelf aan ons verstrekt. Hieronder vindt u
        een overzicht van de persoonsgegevens die wij verwerken:
      </p>
      <ul>
        <li>- Voor- en achternaam</li>
        <li>- Adresgegevens</li>
        <li>- E-mailadres</li>
        <li>- Gegevens over uw activiteiten op onze website</li>
      </ul>

      <h2>Bijzondere en/of gevoelige persoonsgegevens die wij verwerken</h2>
      <p>
        Onze website en/of dienst heeft niet de intentie gegevens te verzamelen
        over websitebezoekers die jonger zijn dan 16 jaar. Tenzij ze toestemming
        hebben van ouders of voogd. We kunnen echter niet controleren of een
        bezoeker ouder dan 16 is. Wij raden ouders dan ook aan betrokken te zijn
        bij de online activiteiten van hun kinderen, om zo te voorkomen dat er
        gegevens over kinderen verzameld worden zonder ouderlijke toestemming.
        Als u er van overtuigd bent dat wij zonder die toestemming persoonlijke
        gegevens hebben verzameld over een minderjarige, neem dan contact met
        ons op via info@digidoos.be, dan verwijderen wij deze informatie.
      </p>

      <h2>
        Met welk doel en op basis van welke grondslag wij persoonsgegevens
        verwerken
      </h2>
      <p>Digidoos verwerkt uw persoonsgegevens voor de volgende doelen:</p>
      <ul>
        <li>- Het afhandelen van uw betaling</li>
        <li>
          - U te kunnen bellen of e-mailen indien dit nodig is om onze
          dienstverlening uit te kunnen voeren
        </li>
        <li>- Om goederen en diensten bij u af te leveren</li>
        <li>- Verzenden van promotionele e-mails of brieven over Digidoos</li>
      </ul>

      <h2>Geautomatiseerde besluitvorming</h2>
      <p>
        Digidoos neemt geen besluiten op basis van geautomatiseerde verwerkingen
        over zaken die (aanzienlijke) gevolgen kunnen hebben voor personen. Het
        gaat hier om besluiten die worden genomen door computerprogramma's of
        -systemen, zonder dat daar een mens (bijvoorbeeld een medewerker van
        Digidoos) tussen zit.
      </p>

      <h2>Hoe lang we persoonsgegevens bewaren</h2>
      <p>
        Digidoos bewaart uw persoonsgegevens standaard 5 jaar. Mocht u
        vroegtijdig uit onze systemen verwijdert willen worden, gelieven ons dan
        te contacteren via info@digidoos.be
      </p>

      <h2>Delen van persoonsgegevens met derden</h2>
      <p>
        Digidoos verkoopt uw gegevens niet aan derden en verstrekt deze
        uitsluitend indien dit nodig is voor de uitvoering van onze overeenkomst
        met u of om te voldoen aan een wettelijke verplichting. Met bedrijven
        die uw gegevens verwerken in onze opdracht (zoals ons hostingbedrijf),
        sluiten wij een bewerkersovereenkomst om te zorgen voor eenzelfde niveau
        van beveiliging en vertrouwelijkheid van uw gegevens. Digidoos blijft
        verantwoordelijk voor deze verwerkingen.
      </p>

      <h2>Cookies, of vergelijkbare technieken, die wij gebruiken</h2>
      <p>
        Digidoos gebruikt alleen technische en functionele cookies. Een cookie
        is een klein tekstbestand dat bij het eerste bezoek aan deze website
        wordt opgeslagen op uw computer, tablet of smartphone. De cookies die
        wij gebruiken zijn noodzakelijk voor de technische werking van de
        website en uw gebruiksgemak. Ze zorgen ervoor dat de website naar
        behoren werkt en onthouden bijvoorbeeld uw voorkeursinstellingen. Ook
        kunnen wij hiermee onze website optimaliseren. U kunt zich afmelden voor
        cookies door uw internetbrowser zo in te stellen dat deze geen cookies
        meer opslaat. Daarnaast kunt u ook alle informatie die eerder is
        opgeslagen via de instellingen van uw browser verwijderen.
      </p>

      <h2>Gegevens inzien, aanpassen of verwijderen</h2>
      <p>
        U heeft het recht om uw persoonsgegevens in te zien, te corrigeren of te
        verwijderen. Daarnaast heeft u het recht om uw eventuele toestemming
        voor de gegevensverwerking in te trekken of bezwaar te maken tegen de
        verwerking van uw persoonsgegevens door Digidoos en heeft u het recht op
        gegevensoverdraagbaarheid. Dat betekent dat u bij ons een verzoek kunt
        indienen om de persoonsgegevens die wij van u beschikken in een
        computerbestand naar u of een ander, door u genoemde organisatie, te
        sturen. U kunt een verzoek tot inzage, correctie, verwijdering,
        gegevensoverdraging van uw persoonsgegevens of verzoek tot intrekking
        van uw toestemming of bezwaar op de verwerking van uw persoonsgegevens
        sturen naar info@digidoos.be. Om er zeker van te zijn dat het verzoek
        tot inzage door u is gedaan, vragen wij u een kopie van uw
        identiteitsbewijs met het verzoek mee te sturen. Maak in deze kopie uw
        pasfoto, MRZ (machine readable zone, de strook met nummers onderaan het
        paspoort), paspoortnummer en Burgerservicenummer (BSN) zwart. Dit ter
        bescherming van uw privacy. We reageren zo snel mogelijk, maar binnen
        vier weken, op uw verzoek. Digidoos wil u er tevens op wijzen dat u de
        mogelijkheid heeft om een klacht in te dienen bij de nationale
        toezichthouder, de Autoriteit Persoonsgegevens. Dat kan via de volgende
        link:
        https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons
      </p>

      <h2>Hoe wij persoonsgegevens beveiligen</h2>
      <p>
        Digidoos neemt de bescherming van uw gegevens serieus en neemt passende
        maatregelen om misbruik, verlies, onbevoegde toegang, ongewenste
        openbaarmaking en ongeoorloofde wijziging tegen te gaan. Als u de indruk
        heeft dat uw gegevens niet goed beveiligd zijn of er aanwijzingen zijn
        van misbruik, neem dan contact op met onze klantenservice of via
        info@digidoos.be
      </p>
    </div>
  );
}

export default Privacy;
