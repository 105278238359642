import React, { useEffect, useState } from "react";
import { FaArrowRightLong } from "react-icons/fa6";
import AnimatedComponent from "./AnimatedComponent";
import { Link, useNavigate } from "react-router-dom";
import ThankYouImage from "../assets/thank_you.png";
import { MdChevronLeft } from "react-icons/md";
import axios from "axios";
import { miscEv } from "../misc/ev";
import { FaCheck } from "react-icons/fa6";

function Form2WelcomeWithPricingAndIntake() {
  const [email, setEmail] = useState("");
  const [allowNavigatingNext, setAllowNavigatingNext] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    miscEv("ir");
  }, []);

  useEffect(() => {
    setAllowNavigatingNext(emailInputIsValid());
  }, [email]);

  function emailInputIsValid() {
    let isValid = true;
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const emailInputIsValid = emailPattern.test(email);
    if (email.length < 1 || !emailInputIsValid) {
      isValid = false;
    }
    return isValid;
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      // Trigger the button click or any other action here
      handleSubmit();
    }
  };

  const [optional_usb, setOptional_usb] = useState(false);
  const [optional_return, setOptional_return] = useState(false);
  const [optional_cloud, setOptional_cloud] = useState(false);

  /*
  const handleSubmit = async () => {
    try {
      // Get the unique code from localStorage
      const unique_code = localStorage.getItem("unique_code");

      // Get the API base URL from the environment variables
      const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

      // Make POST request to create the WaitlistOrder
      await axios.post(`${apiBaseUrl}/api/waitlist-orders`, {
        unique_code,
        optional_usb,
        optional_return,
        optional_cloud,
      });

      // Navigate to the next step
      navigate("/stap3");
    } catch (error) {
      console.error("Error creating waitlist order:", error);
      alert("Er is iets misgegaan. Probeer het opnieuw.");
    }
  };
  */

  const handleSubmit = async (e) => {
    if (e) {
      e.preventDefault();
    }

    if (emailInputIsValid()) {
      try {
        // Get the API base URL from the environment variables
        const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

        // Make POST request to create the registrant
        const response = await axios.post(`${apiBaseUrl}/api/v2/registrants`, {
          email,
        });

        // Store the unique_code in localStorage
        localStorage.setItem("unique_code", response.data.unique_code);

        // Navigate to the next step
        navigate("/stap2");
      } catch (error) {
        console.error("Error creating registrant:", error);
        alert("Er is iets misgegaan. Probeer het opnieuw.");
      }
    } else {
      alert("Controleer of het e-mailadres correct ingevuld is. Bedankt!");
    }
  };

  return (
    <AnimatedComponent>
      <div className="formWelcomeWithPricingAndIntake">
        <h2>
          Welkom!<br></br>Fijn dat je interesse hebt in mijn project!
        </h2>
        <img className="thank-you-image" src={ThankYouImage} alt="Bedankt!" />
        <p className="subtitle">Inbegrepen:</p>
        <div className="included">
          <p>
            <FaCheck />
            1.000 scans <span>(daarna €0,10 per foto)</span>
          </p>
          <p>
            <FaCheck />
            GRATIS ophaling bij u thuis
          </p>
          <p>
            <FaCheck />
            GRATIS terugbezorging bij u thuis
          </p>
          <p>
            <FaCheck />
            15% korting bij smartphoto.be
          </p>
          <p className="price">
            <span className="old-price">€249</span>€199{" "}
            <span className="extra">- Tijdelijke korting</span>
          </p>
        </div>

        <p className="subtitle optional">Optioneel:</p>
        <div className="optional-package">
          <div className="package">
            <h3>
              <span>USB-stick </span>
              <span className="price">+ €29</span>
            </h3>
            <p>Ontvang alle ingescande foto's ook op een USB-stick.</p>
          </div>
        </div>

        <p className="a-question">
          Een vraag? <FaArrowRightLong />
          <a href="mailto:info@digidoos.be">info@digidoos.be</a>
        </p>

        <h2 className="smaller">Zeker van je korting zijn?</h2>
        <p className="for-who">
          <FaArrowRightLong /> €50 korting geldig t.e.m. 30 september
        </p>

        <input
          type="email"
          placeholder="E-mailadres"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <span className="privacy">
          <Link to="/privacy" target="_blank">
            (U gaat akkoord met het <span>privacybeleid</span>.)
          </Link>
        </span>

        <button onClick={handleSubmit} className={`button-a`}>
          Korting aanvragen <FaArrowRightLong />
        </button>
        <Link className="back-button-text" to="/">
          <MdChevronLeft />
          Terug
        </Link>
      </div>
    </AnimatedComponent>
  );
}

export default Form2WelcomeWithPricingAndIntake;

/*
  <div className="optional-package">
          <div className="checkbox-container">
            <input
              type="checkbox"
              checked={optional_cloud}
              onChange={(e) => setOptional_cloud(e.target.checked)}
            />
          </div>
          <div className="package">
            <h3>
              <span>Cloud backup </span>
              <span className="price">+ €149</span>
            </h3>
            <p>
              Laat Digidoos 10 jaar lang een backup van uw foto's in de cloud
              bewaren.
            </p>
          </div>
        </div>
        */
