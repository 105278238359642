import React, { useEffect } from "react";
import AnimatedComponent from "./AnimatedComponent";
import DoneImage from "../assets/done.png";

function FormDone() {
  return (
    <AnimatedComponent>
      <div className="form-done">
        <img className="done-image" src={DoneImage} alt="Klaar!" />
      </div>
    </AnimatedComponent>
  );
}

export default FormDone;
