import React, { useState } from "react";
import AnimatedComponent from "./AnimatedComponent";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function FormReferral() {
  const [contact1, setContact1] = useState("");
  const [contact2, setContact2] = useState("");
  const [contact3, setContact3] = useState("");
  const [contact4, setContact4] = useState("");

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Get the unique code from localStorage
      const unique_code = localStorage.getItem("unique_code");

      // Get the API base URL from the environment variables
      const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

      // Make POST request to create the WaitlistOrder
      await axios.post(`${apiBaseUrl}/api/referrals`, {
        unique_code,
        contact1,
        contact2,
        contact3,
        contact4,
      });

      // Navigate to the next step
      navigate("/einde");
    } catch (error) {
      console.error("Error creating referral(s):", error);
      alert("Er is iets misgegaan. Probeer het opnieuw.");
    }
  };

  return (
    <AnimatedComponent>
      <div className="form-referral">
        <h2>Versnel de lancering</h2>
        <p>
          Jouw netwerk kan een grote impact maken.
          <br></br>
          <br></br>
          Ken je een vriend of familielid die ook een doos oude foto's heeft en
          deze dienst interessant zou vinden? Vul hun e-mailadres of
          telefoonnummer hieronder in!
          <br></br>
          <br></br>
          Indien niet, dan mag je deze pagina sluiten.
        </p>
        <input
          type="text"
          placeholder="E-mailadres of telefoonnummer"
          value={contact1}
          onChange={(e) => setContact1(e.target.value)}
        />
        <input
          type="text"
          placeholder="E-mailadres of telefoonnummer"
          value={contact2}
          onChange={(e) => setContact2(e.target.value)}
        />
        <input
          type="text"
          placeholder="E-mailadres of telefoonnummer"
          value={contact3}
          onChange={(e) => setContact3(e.target.value)}
        />
        <input
          type="text"
          placeholder="E-mailadres of telefoonnummer"
          value={contact4}
          onChange={(e) => setContact4(e.target.value)}
        />
        <button className="button-a" onClick={handleSubmit}>
          Breng hen op de hoogte
        </button>
      </div>
    </AnimatedComponent>
  );
}

export default FormReferral;
